.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */
[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: #ef890b; /* Color de fondo del botón */
  --amplify-components-button-primary-color: #ffffff; /* Color del texto del botón */
  --amplify-components-button-primary-hover-background-color: #e3a14f; /* Color de fondo del botón cuando se pasa el ratón por encima */
  --amplify-components-button-primary-hover-color: #ffffff; /* Color del texto del botón cuando se pasa el ratón por encima */
}
/* customStyles.css */
[data-amplify-authenticator] {
  --amplify-components-tabs-item-active-border-color: #ef890b; /* Cambia el color a tu preferencia */
  --amplify-components-tabs-item-active-color: #ef890b; /* Cambia el color a tu preferencia */
}

[data-amplify-tabs-item]:hover {
  --amplify-tabs-item-color: #e3a14f; /* Color de texto cuando se pasa el ratón por encima */
  --amplify-tabs-item-border-color: #e3a14f; /* Color de borde cuando se pasa el ratón por encima */
}
